/** @jsx jsx */

import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import Header from './header'
import flamingo from '../images/flamingowit.png'
import bg2 from '../images/bgimg5.jpg'

import { globalCss, variables, colors } from '../styles/global'
import { Global, css, jsx } from '@emotion/react'

import { FaInstagram, FaFacebookSquare } from 'react-icons/fa'
import { IoCallOutline } from 'react-icons/io5'
import { FiMail } from 'react-icons/fi'
import CookieConsentPopup from './CookieConsentPopup'
import useStore from '../store'

interface Props {
  children: any
  hideMenu: boolean
  showButton: boolean
  title: string
  description: string
}

const Layout = ({
  children,
  hideMenu,
  showButton = false,
  description,
  title,
}: Props) => {
  const setIsShowingCookiePopup = useStore(
    (state) => state.setIsShowingCookiePopup
  )
  return (
    <div
      id="container"
      css={css`
        overflow-x: hidden;
        position: relative;
      `}
    >
      <CookieConsentPopup />
      <Global
        styles={css`
          ${globalCss}
        `}
      />
      <Header hideMenu={hideMenu} />

      <main>{children}</main>
      <footer
        css={css`
          overflow: hidden;
          background-color: ${colors.pink};
          background-image: url('/fotos/bannernieuw2.jpg');
          background-repeat: no-repeat;
          background-size: cover;
          background-position: 50% 80%;
          position: relative;
          color: ${colors.white};
          width: 90vw;
          padding: 100px ${variables.HPaddingDesktop}px 150px;
          font-size: 1.3rem;
          display: flex;
          flex-direction: column;
          ${variables.mobile} {
            padding: 20px ${variables.HPaddingMobile}px 50px
              ${variables.HPaddingMobile}px;
            font-size: 1rem;
          }
        `}
      >
        <div
          css={css`
            position: absolute;
            background-color: black;
            opacity: 60%;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            z-index: 0;
          `}
        ></div>
        <h4
          css={css`
            margin-bottom: 40px;
            font-family: 'PT Sans', sans-serif;
            text-shadow: none;
            color: ${colors.white};
            z-index: 1;
          `}
        >
          MANAGEMENT EN BOEKINGEN
        </h4>
        <a
          className="hoverScale"
          href="https://www.robbenentertainment.nl"
          target="_blank"
          css={css`
            width: fit-content;
            margin-bottom: 9px;
            text-decoration: underline;
            color: ${colors.white};
            z-index: 1;
          `}
        >
          <b> ww.robbenentertainment.nl</b>
        </a>
        <a
          className="hoverScale"
          href="tel: +31638590799"
          css={css`
            width: fit-content;
            margin-bottom: 9px;
            text-decoration: none;
            color: ${colors.white};
            z-index: 1;
          `}
        >
          <IoCallOutline color={colors.white} className="react-icons" />
          <b> - Tel: 0620480294</b>
        </a>
        <a
          className="hoverScale"
          href="mailto: info@robbenentertainment.nl"
          css={css`
            width: fit-content;
            margin-bottom: 9px;
            text-decoration: none;
            color: ${colors.white};
            user-select: text;
            z-index: 1;
          `}
        >
          <FiMail color={colors.white} className="react-icons" />
          <b> - Mail: info@robbenentertainment.nl</b>
        </a>
        <a
          className="hoverScale"
          href="https://www.instagram.com/first_class_flamingos/"
          css={css`
            width: fit-content;
            margin-bottom: 9px;
            text-decoration: none;
            color: ${colors.white};
            z-index: 1;
          `}
        >
          <FaInstagram color={colors.white} className="react-icons" />
          <b> - Instagram</b>
        </a>
        <a
          className="hoverScale"
          href="https://www.facebook.com/firstclassflamingos"
          css={css`
            width: fit-content;
            margin-bottom: 9px;
            text-decoration: none;
            color: ${colors.white};
            z-index: 1;
          `}
        >
          <FaFacebookSquare color={colors.white} className="react-icons" />
          <b> - Facebook</b>
        </a>
        <Link to="/contact" style={{ zIndex: 1 }}>
          <div>
            <u>Vraag een vrijblijvende prijsopgave aan</u>
          </div>
        </Link>
        <Link to="/downloads" style={{ zIndex: 1 }}>
          <div
            css={css`
              margin-top: 40px;
              font-size: 1.1rem;
              ${variables.mobile} {
                font-size: 0.9rem;
              }
            `}
          >
            <u>Download Rider / Press Kit</u>
          </div>
        </Link>
        <p
          className=""
          css={css`
            width: fit-content;
            font-size: 0.8rem;
            opacity: 0.6;
            margin-top: 40px;
            margin-bottom: -40px;
            z-index: 1;
          `}
        >
          Website powered by{' '}
          <a href="//www.rubendenbrokwebdeveloper.com" target="blank">
            <u>Ruben den Brok Creative Design</u>
          </a>
        </p>
        <p
          onClick={() => setIsShowingCookiePopup(true)}
          className=""
          css={css`
            width: fit-content;
            font-size: 0.8rem;
            opacity: 0.6;
            margin-top: 40px;
            margin-bottom: -40px;
            cursor: pointer;
          `}
        >
          Cookie settings
        </p>
        {/* <div
          css={css`
            position: absolute;
            right: 150px;
            top: 30px;
            width: 350px;
            height: 100%;
            ${variables.mobile} {
              display: none;
            }
          `}
        >
          <GatsbyImage
            image={
              logo.logoData.edges[0].node.frontmatter.logo.childImageSharp
                .gatsbyImageData
            }
            alt="logo"
          />
        </div> */}
      </footer>
      {showButton && (
        <div
          css={css`
            position: fixed;
            background-color: #1ea34f;
            color: ${colors.white};
            border: 2px solid ${colors.white};
            width: 250px;
            border-radius: 15px;
            padding: 7px 15px;
            text-align: center;
            ${variables.mobile} {
              display: none;
            }
            bottom: 30px;
            right: 30px;
            z-index: 9999;
            transform: translateY(150px);
            animation: popup 0.6s forwards;

            @keyframes popup {
              from {
                transform: translateY(150px);
              }
              to {
                transform: translateY(0);
              }
            }
          `}
        >
          {' '}
          <Link to="/kijksessie">
            <div className="hoverScale">Vrijblijvende Prijsopgave</div>
          </Link>
        </div>
      )}
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
